@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
}

.list{
    max-width: 1200px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    margin-bottom: 30px;
}

.button{
    @include button;
    max-width: 300px;
    width: 100%;
    height: 50px;
    border: 0;
}

.glare{
    @include glare
}