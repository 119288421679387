@import '../../base.scss';


    .main {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .item {
        color: $white;
        background-image: url(../../image/InitialPage/img.webp);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: cover;
    }

    .overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, .2);
    }

.container{
    box-sizing: border-box;
    max-width: 1400px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title{
    max-width: 700px;
    font-size: 56px;
    line-height: 1.1;
    font-weight: 700;
    color: $white;
    margin: 0;
    position: relative;
    text-transform: uppercase;
}

.text{
    color: $white;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    text-align: left;
    margin: 0;
}

.box{
    margin-top: 30px;
    max-width: 470px;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
}

.box_item{
    display: flex;
    align-items: center;
}

.image{
    width: 40px;
    height: auto;
    margin-right: 15px;
}

.button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
}

.glare{
    @include glare
}



@media(max-width: 930px){
    .title{
        max-width: 650px;
        font-size: 50px;
    }
}

@media(max-width: 768px){

    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .title{
        font-size: 46px;
        max-width: 550px;
    }
}

@media(max-width: 580px){

    .title{
        max-width: 430px;
        font-size: 44px;
    }

    .box{
        margin-top: 10px;
        max-width: 420px;
        display: grid;
        box-sizing: border-box;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 10px;
    }

    .image{
        width: 30px;
    }

    .text{
        font-size: 14px;
    }
}

@media(max-width: 450px){

    .main {
        height: 90vh;
    }

    .overlay{
        height: 90vh;
    }

    .title{
        font-size: 38px;
    }
}

@media(max-width: 425px){
    .box{
        margin-top: 20px;
        max-width: 100%;
    }

    .title{
        max-width: 350px;
        font-size: 32px;
    }
}

@media(max-width: 380px){
    .title{
        max-width: 300px;
        font-size: 28px;
    }
}