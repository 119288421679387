@import '../../base.scss';

:root {
    --swiper-pagination-color: #f6c150;
    --swiper-pagination-bullet-width: 15px; 
    --swiper-pagination-bullet-height: 15px;
    --swiper-pagination-bullet-inactive-color: #FFF;
    --swiper-pagination-bullet-inactive-opacity: 0.5;
    --swiper-pagination-fraction-color: #181818;
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.title{
    @include title;
    z-index: 1;
    margin-bottom: 30px;
    max-width: 900px;
    padding: 0 10px;
}

.box{
    @include main-column;
    padding: 50px 0;
}

.slider{
    max-width: 100%;
    width: 100%;

}

.image{
    width: 100%;
    height: auto;

    &:hover{
        transition: all .3s;
        cursor: pointer;
        filter: contrast(60%);
        
    }
}

@media(max-width: 768px){

    .box{
        padding: 40px 0;
    }
}  

@media(max-width: 425px){
    .box{
        margin-top: 50px;
        padding: 30px 0;
    }
}
