$white: #FFF;
$black: #252525;
$blue: #0700ff;
$red:#CD2122;
$element: #f27844;

/*background*/
$background: #f7f2eb;
$background-footer: #3c3f42;
$background-header:rgba(#252525, .6);
$box:  rgba($element, .2);
$box-input:  #f2fafa;


/*title & text*/
$title: #2e3342;
$text: #2e3342;
$text-box: #898989;

/*button*/
$button-background: $element;
$button-color: $white;
$button-border: #da4c0f;
$button-shadow: #f29544;



$background-input: #353a3f;
$advantages: #2e3342;

@keyframes flareAnimation {
    0% {
        left: -150%;
    }
    100% {
        left: 150%;
    }
}


@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 50px 20px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 40px 10px;
    }

    @media(max-width: 425px){
        padding: 30px 10px;
    }
}

@mixin box-column-no-padding {
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin title {
    font-weight: 600;
    font-size: 38px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: $title;
    margin: 0;

    @media(max-width: 1020px){
        font-size: 32px;
    }

    @media(max-width: 768px){
            font-size: 26px;
        }

        & span {
            color: $element;
        }
}

@mixin text {
    color: $text;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0 0;

    @media(max-width: 1100px){
        font-size: 16px;
        line-height: 1.2;
}

    @media(max-width: 425px){
        font-size: 14px;
}
}

@mixin button{
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    border: none;
    padding: 0;
    color: $button-color;
    border-radius: 16px;
    background: $button-background;
    box-shadow: 0 4px 0 $button-border;
    transform: translateY(-5px);
    position: relative;
    overflow: hidden;


    &:hover{
        cursor: pointer;
        box-shadow: 0px 0px 8px 4px rgba($button-shadow, 0.25), 0px 0px 4px 4px rgba($button-shadow, 0.25);
        transform: scale( .95 );
        transition: all .5s ease-out;
    }
}

@mixin glare{
    position: absolute;
    top: 0;
    height: 100%;
    width: 45px;
    transform: skewX(-45deg);
    animation: flareAnimation;
    left: -150%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
    animation: flareAnimation 3s infinite linear;
}

@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}



