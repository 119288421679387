@import '../../base.scss';


.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.container{
    @include main-column;
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba( $background-footer, .4 );
    padding: 20px 10px;
    position: relative;
    z-index: 1;
    color: $text;
    border-radius: 16px;

    &:hover{
        cursor: pointer;
        transform:scale(1.05);
        transition: .5s;
        background-color: $background-footer;
        color: $white;
    }
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $element;
    box-shadow: 0px 0px 8px 4px rgba($button-shadow, 0.25), 0px 0px 4px 4px rgba($button-shadow, 0.25);
}

.icon{
    width: 50px;
    height: 50px;
}

.item_title{
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    margin: 20px 0 0;

}

.item_text{
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;
}


.button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
}

.glare{
    @include glare
}

.image{
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 1%;
}


@media(max-width: 768px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .image{
        width: 250px;
    }
}